@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

#root{
  @apply w-full h-full;
  @apply min-w-52 max-w-3xl;
  @apply text-center;
  @apply m-auto;
  @apply bg-white;
  @apply flex flex-col;
}

html{
  width:100%;
  height:100%;
}
body {
  width:100%;
  height:100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: Poppines;
  src: url(./assets/fonts/Poppins/Poppins-SemiBold.ttf);
}

.poppines_font {
  font-family: Poppines !important;
}

* {
  font-family: Poppines;
}

fieldset {
  border: none !important;
}

